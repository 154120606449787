//https://threejs.org/examples/webgl_geometry_text.html#62FF00100#three.js
// https://stackoverflow.com/questions/14737549/infinite-plane-of-wireframe-squares-in-three-js

// Grouper les lignes + le plane et le faire répéter en avançant ?

class TronScene{

    constructor(){

		this.scene = null;
		this.camera = null;
		this.renderer = null;

		this.controls = null;

		// Objects
		this.cube = null;

    	this.init();

    }

    init(){

		// Scene par défaut
		this.scene = new THREE.Scene();
		this.camera = new THREE.PerspectiveCamera( 75, window.innerWidth / window.innerHeight, 1, 1000 );

		//
		this.renderer = new THREE.WebGLRenderer();
		this.renderer.setPixelRatio(window.devicePixelRatio);
		this.renderer.setSize( window.innerWidth, window.innerHeight );
		this.renderer.setClearColor(new THREE.Color("hsl(0, 0%, 10%)"));
		document.body.appendChild( this.renderer.domElement );

		// Controls
		this.controls = new THREE.OrbitControls(this.camera, this.renderer.domElement);
		this.controls.enableDamping = true;
		this.controls.dampingFactor = 0.25;
		this.controls.enableZoom = true;

		// Camera
		this.camera.position.z = 70;
		this.camera.position.y = -75;

		// FOG ?
		// this.scene.fog = new THREE.FogExp2( 0x111111, 0.0064 );
		// this.renderer.setClearColor( this.scene.fog.color, 1 );
		this.makeLights();
		this.makeCube();

		this.animate();

	}

	makeLights(){

		var keyLight = new THREE.DirectionalLight(new THREE.Color('hsl(30, 100%, 75%)'), 1.0);
		keyLight.position.set(-100, 0, 100);
		this.scene.add(keyLight);


		var ambientLight = new THREE.AmbientLight(0x999999 );
		this.scene.add(ambientLight);

		var lights = [];
		lights[0] = new THREE.DirectionalLight( 0xffffff, 1 );
		lights[0].position.set( 1, 0, 0 );
		lights[1] = new THREE.DirectionalLight( 0x11E8BB, 1 );
		lights[1].position.set( 0.75, 1, 0.5 );
		lights[2] = new THREE.DirectionalLight( 0x8200C9, 1 );
		lights[2].position.set( -0.75, -1, 0.5 );
		this.scene.add( lights[0] );
		this.scene.add( lights[1] );
		this.scene.add( lights[2] );

	}

	makeCube() {

		/// BOX
		var geometry = new THREE.BoxGeometry( 1, 1, 1 );
		var material = new THREE.MeshBasicMaterial( { color: 0x00ff00 } );
		this.cube = new THREE.Mesh( geometry, material );
		this.scene.add( this.cube );

		var size = 100;
		var divisions = 10;

		// Grid Helper
		this.gridHelper = new THREE.GridHelper( size, divisions );
		this.gridHelper.setColors(0xFF55FF,0x5555FF);
		this.gridHelper.geometry.rotateX( Math.PI / 2 );

		// var vector = new THREE.Vector3( 4, 1, 10 );
		// this.gridHelper.lookAt( vector );

		this.scene.add( this.gridHelper );


		/// On essaye des trucs
		this.plane = new THREE.Mesh(
			new THREE.PlaneBufferGeometry( 10000, 10000 ),
			new THREE.MeshBasicMaterial( { color: 0x135485, opacity: 0.5, transparent: true } )
		);

		this.plane.rotation.x = - Math.PI / 2;
		this.scene.add( this.plane );


		//// Lines ////
		var geometry = new THREE.Geometry();
		geometry.vertices.push(new THREE.Vector3( -500, 0, 0 ) );
		geometry.vertices.push(new THREE.Vector3( 500, 0, 0 ) );

		var linesMaterial = new THREE.LineBasicMaterial( { color: 0x135485, opacity: 1, linewidth: 100 } );

		for ( var i = 0; i <= 20; i ++ ) {

			var line = new THREE.Line( geometry, linesMaterial );
			line.position.z = ( i * 50 ) - 500;
			this.scene.add( line );

			var line = new THREE.Line( geometry, linesMaterial );
			line.position.x = ( i * 50 ) - 500;
			line.rotation.y = 90 * Math.PI / 180;
			this.scene.add( line );
		}


	}

	animate() {

		window.requestAnimationFrame( () => { this.animate(); } );

		this.cube.rotation.x += 0.01;
		this.cube.rotation.y += 0.01;

		// this.gridHelper.y += 1;

		// this.plane.rotation.x -= 0.0010;
		// this.plane.rotation.y += 0.0020;

		this.controls.update();

		this.render();

	}

	render() {
		this.renderer.render( this.scene, this.camera );
	}

}
