class Home extends AbstractState {

    constructor(){
		super();

		this._NAME = 'Home';

		this.dom = {
			section : $('.section-home'),
			title   : $('.home__title'),
			subtitle: $('.home__subtitle'),
			year    : $('.home__year'),
			triangle: $('.home__triangle')
		};

		this.button = $('.home__button');

    }

    open() {
		super.open();

		let tl = new TimelineMax({
			paused: true
		});

		tl.fromTo( this.dom.triangle, 0.3, {opacity: 0, y: 30}, { opacity: 1, y: 0 }, "-=0" );
		tl.fromTo( this.dom.title, 0.3, {opacity: 0, y: 30}, { opacity: 1, y: 0 }, "-=0.04" );
		tl.fromTo( this.dom.subtitle, 0.3, {opacity: 0, y: 30}, { opacity: 1, y: 0 }, "-=0.08" );
		tl.fromTo( this.dom.year, 0.3, {opacity: 0, y: 30}, { opacity: 1, y: 0 }, "-=0.12" );
		tl.fromTo( this.button, 0.5, {opacity: 0, y: 30, scale: 0.8}, { opacity: 1, y: 0, scale: 1, transformOrigin: 'center' }, "-=0.14" );

		tl.play();

	}

	close( _cb ) {
		super.close();

		TweenMax.to( this.button, 0.3, { opacity: 0, onComplete: () => {

			// Return async
			_cb();

		} } );

	}

}
