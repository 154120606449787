// https://developers.google.com/youtube/iframe_api_reference?hl=fr

class MusicPlayer {

	constructor() {

		this.init();

	}

	init() {

		// 2. This code loads the IFrame Player API code asynchronously.
		var tag = document.createElement('script');

		tag.src = "https://www.youtube.com/iframe_api";
		var firstScriptTag = document.getElementsByTagName('script')[0];
		firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

	}

	static onPlayerReady(event) {
		document.querySelector('.track__name').innerHTML = MUSICPLAYER.getVideoData().title;
	}

	static playVideo() {
		MUSICPLAYER.playVideo();
	}

	static stopVideo() {
		MUSICPLAYER.stopVideo();
	}
	static changeVideo( _id ){
		document.querySelector('.track__name').innerHTML = 'loading ...';

		// Le second parametre est le temps du démarrage
		// On fait un truc rapide pour commencer les morceaux sans l'intro
		let startTime = 0;

		switch (_id) {
			case 'mnkntLDNXCs':
				startTime = 2;
				break;
			case 'xFrGuyw1V8s':
				startTime = 2;
				break;
			case 'xFrGuyw1V8s':
				startTime = 2;
				break;
			case 'NjxNnqTcHhg':
				startTime = 10;
				break;
			case 'GDpmVUEjagg':
				startTime = 18;
				break;
			case 'ZoEwR9_Sy_M':
				startTime = 8;
				break;
			default:
				break;
		}

		MUSICPLAYER.loadVideoById(_id, startTime, "large");

	}

	static onPlayerStateChange( _state ) {

		if( _state.data === 1 ){
			document.querySelector('.track__name').innerHTML = MUSICPLAYER.getVideoData().title;
			// MUSICPLAYER.isAlreadyPlaying = true;
		}else {
			// MUSICPLAYER.isAlreadyPlaying = false;
		}

	}
}

// 3. This function creates an <iframe> (and YouTube player)
//    after the API code downloads.
function onYouTubeIframeAPIReady() {

	MUSICPLAYER = new YT.Player('musicplayer', {
		height: '64',
		width: '64',
		videoId: 'SnZCPreCTEU',
		events: {
			'onReady': MusicPlayer.onPlayerReady,
			'onStateChange': MusicPlayer.onPlayerStateChange
		}
	});

	MUSICPLAYER.isAlreadyPlaying = false;

}
