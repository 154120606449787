class Choice extends AbstractState {

    constructor(){
		super();
		console.log('::Choice');

		this._NAME = "Choice";

		this.dom = {
			section : $('.section-choice'),
			items   : $$('.choice__item'),
			cursor  : $('.choice__cursor')
		};

		this.index = 0;

		this.bgAnimation = null;

    }

    open() {
		super.open();

		TweenMax.staggerFromTo( this.dom.items, 0.4, { y:10, opacity: 0 }, { y: 0, opacity: 1 }, 0.18 );

		this.cursorWidth = this.dom.cursor.offsetWidth;

		TweenMax.fromTo(this.dom.cursor, 0.4, { y: 0 }, {y: -20, yoyo:true, repeat:-1});

		TweenMax.to(this.dom.cursor, 0.2, {opacity: 1} );

		// Background Animation
		var background = backgrounds['state-choice'];
		var bg2 = "linear-gradient(to top, rgb(214, 3, 219) 40%, rgb(0, 36, 138) 90%, rgb(0, 36, 138) 100%)";

		this.bgAnimation = TweenMax.fromTo("body", 1,
			{
				backgroundImage: background
			},
			{
				backgroundImage: bg2,
				yoyo: true,
				repeat: -1,
				ease: Power0.easeNone
			}
		);
		//

		// Events
		this.onChangeFunction = this.onChange.bind(this);
		this.onresizeDebounceFunction = this.onresizeDebounce.bind(this);

		this.dom.items.forEach(
			_el => _el.addEventListener('mouseenter', this.onChangeFunction, false )
		);

		window.addEventListener('resize', this.onresizeDebounceFunction , false );

		this.onChangePos();

	}

	close( _cb ) {
		super.close();

		this.bgAnimation.kill();

		this.dom.items.forEach(
			_el => _el.removeEventListener('mouseenter', this.onChangeFunction, false )
		);

		window.removeEventListener('resize', this.onresizeDebounceFunction , false );

		TweenMax.to(this.dom.cursor, 0.2, {opacity: 0} );

		TweenMax.staggerFromTo( this.dom.items, 0.2, { y:0, opacity: 1 }, { y: -10, opacity: 0 }, 0.04, () => {
			_cb();
		} );

	}

	/***** */
	/** CLASS METHODS **/

	onresizeDebounce() {

		var resizeFn = debounce(() => {

			this.onChangePos();

		}, 250);

		resizeFn();
	}

	onChange( e ) {

		let _el = e.currentTarget;

		let name = _el.getAttribute('data-choice');

		this.index = Array.prototype.slice.call(this.dom.items).indexOf( _el );

		MUSICPLAYER.isAlreadyPlaying = true;
		MusicPlayer.changeVideo( VIDEOS[name][Math.floor(Math.random() * VIDEOS[name].length)]);

		this.onChangePos();

	}

	onChangePos() {

		let position = this.dom.items[this.index].offsetLeft + this.dom.items[this.index].offsetWidth / 2 - this.cursorWidth / 2;

		TweenMax.to(this.dom.cursor, 0.4, { x: position });

	}

}
