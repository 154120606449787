/**
 * 	https://dev.to/rishavs/making-a-single-page-app-in-ye-good-olde-js-es6-3eng
 */

class Router{

    constructor(){

		this.isFirstTime = true;

    	this.init();

    }

    init(){

		// Listen on hash change :
		window.addEventListener('hashchange', () => { this.router(); });

		// Listen on page load :
		window.addEventListener('load', () => { this.router(); });

	}

	// The router code.
	// Takes a URL, checks against the list of supported routes and then renders the corresponding content page.
	router() {

		// Lazy load view element:
		// const content = null || document.getElementById('page_container');

		// Get the parsed URl from the addressbar
		let request = parseRequestURL();
		// console.log('request -> ', request);

		// Parse the URL and if it has an id part, change it with the string ":id"
		let parsedURL = (request.resource ? '/' + request.resource : '/') + (request.id ? '/:id' : '') + (request.verb ? '/:verb' : '')

		// Get the page from our hash of supported routes.
		// If the parsed URL is not in our list of supported routes, select the 404 page instead
		let page = ROUTES[parsedURL] ? ROUTES[parsedURL] : Error404;

		// content.innerHTML = await page.render();
		// await page.after_render();

		if( this.isFirstTime === true ){
			C.SECTIONS.forEach( ( _el ) => {
				_el.classList.remove('is-active');
			});

			page.open( request.id, request.verb );
		}

		if( C.previousState ){

			// Close with callback
			C.previousState.close(
				() => {
					C.SECTIONS.forEach( ( _el ) => {
						_el.classList.remove('is-active');
					});

					page.open( request.id, request.verb );
				}
			);

		}

		C.previousState = page;
		this.isFirstTime = false;

	}

}
