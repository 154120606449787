/**
 * 	Classe Abstraite qui sert de base à toutes les states
 */

class AbstractState {

	constructor() {

		if (new.target === AbstractState) {
			throw new TypeError("Cannot construct Abstract instances directly");
		}

	}

	open() {

		console.log(`::Open -> ${this._NAME}`);

		this.dom.section.classList.add('is-active');

		// Commun aux states
		const STATENAME = this.dom.section.getAttribute('data-state');

		// On l'applique au body
		C.BODY.setAttribute('data-state', STATENAME);

		let background = backgrounds[STATENAME];

		if(this.mood){
			C.BODY.setAttribute('data-mood', this.mood);
			background = backgrounds[STATENAME][this.mood];
		}

		TweenMax.to("body", 1.4,
			{
				backgroundImage: background
			}
		);

	}

	close() {
		console.log(`::Close -> ${this._NAME}`);
	}

}
