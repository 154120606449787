var ee = new EventEmitter();
var $ = document.querySelector.bind(document);
var $$ = document.querySelectorAll.bind(document);

class Website{

    constructor(){
    	this.init();

    }

    init(){

		TweenLite.defaultEase = Circ.easeOut;

		// const tronScene = new TronScene();
		const router = new Router();
		const musicPlayer = new MusicPlayer();

		// Share moving buttons
		$$('.js-moving-button').forEach( (_el) => { let btn = new Button(_el); }  );

		console.log('%c Mécanisé avec boules à facettes par Le Petit Garage :: https://petitgarage.fr', 'color: #da0a39; font-family: futura; text-transform:uppercase; font-weight: bold;');

	}

}


// Entry point
document.addEventListener('DOMContentLoaded', function(){

	const website = new Website();

});
