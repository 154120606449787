let C = window.C || {};

// GLobal variables
C = {
	index: 0,
	currentState: null,
	previousState: null,
	SECTIONS: $$('.section'),
	BODY: $('body')
};

/*
	Classes / States
*/
const STATES = window.STATES || {
	home: new Home(),
	choice: new Choice(),
	mood: new Mood(),
	party: new Party(),
};

// Party est accessible de partout
const party = new Party();

// Scene3d est accessible de partout
const scene3d = new Scene3d();
scene3d.animate();

/*
	Routes
*/
const ROUTES = window.ROUTES || {
	'/' : STATES.home,
	'/choice/:id/:verb': STATES.mood,
	'/choice/:id': STATES.mood,
	'/choice': STATES.choice,
};

let MUSICPLAYER = null;

const VIDEOS = {
	'disco': [
		'1IdEhvuNxV8',
		'mnkntLDNXCs',
		'-_lx5GAiUDc',
		'xFrGuyw1V8s'
	],
	'techno': [
		'H9iCo4wJsnA',
		'NjxNnqTcHhg',
		'GDpmVUEjagg',
		'gChOifUJZMc'
	],
	'vintage': [
		'IkKh4O1-F8k',
		'gj0Rz-uP4Mk'
	],
	'slow': [
		'izGwDsrQ1eQ',
		'fUis9yny_lI',
		'ZoEwR9_Sy_M'
	]
};

const backgrounds = {
	"state-home" : "linear-gradient(to top, rgba(0,0,0,1) 0%,rgba(20,20,20,1) 90%,rgba(20,20,20,1) 100%)",
	"state-choice": "linear-gradient(to top, rgba(214,3,219,1) 0%, rgba(0,36,138,1) 90%, rgba(0,36,138,1) 100%)",
	"state-mood": {
		"disco": "linear-gradient(to bottom, rgba(209,166,50,1) 0%,rgba(255,240,201,0.8) 24%,rgba(210,168,55,1) 100%)",
		"techno": "linear-gradient(to bottom, rgba(87,209,50,1) 0%,rgba(32,124,202,0.8) 61%,rgba(61,228,183,1) 100%)",
		"vintage": "linear-gradient(to bottom, rgba(217,157,118,1) 0%,rgba(237,198,173,0.8) 61%,rgba(255,235,222,1) 100%)",
		"slow": "linear-gradient(to bottom, rgba(255,175,233,1) 0%,rgba(226,158,244,0.8) 61%,rgba(198,141,255,1) 100%)"
	}
};
