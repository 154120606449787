// Route : Choice/:id

class Mood extends AbstractState {

	constructor() {
		super();

		this._NAME = 'Mood';

		this.mood = '';
		this.verb = null;

		this.dom = {
			section: $('.section-mood'),
			name: $('.mood__name'),
			form: $('.mood__form'),
			input: $('.mood__form #name'),
			content: $('.mood__content'),

			loader: $('.loading-bar'),
			loaderMood: $('.loading-bar__mood'),
			loaderTheme: $('.loading-bar__theme'),

			cover: $('.party__container__cover'),

			share: $('.share'),

			endMood: $('.mood__end .mood__name'),
			endTheme: $('.mood__end .mood__theme'),
		};

		// Comme sur la home
		this.dom.end = {
			all: $('.mood__end'),
			title: $('.mood__end__title'),
			subtitle: $('.mood__end__subtitle'),
			year: $('.mood__end__year'),
			triangle: $('.mood__end__triangle'),
			terms: $('.mood__end__terms')
		};

		/// -> Animation ecran Geneerateuf
		this.tl = new TimelineMax({
			paused: true
		});

		this.createTimeline();

	}

	open(_mood, _verb = null) {

		this.mood = _mood;
		this.verb = _verb;

		super.open();

		// Party cover background
		this.dom.cover.style.background = backgrounds['state-mood'][this.mood];
		this.dom.section.setAttribute('data-mood', this.mood);

		// Si on a un terme recherché, on affiche directement l'etape de résultat
		if (this.verb) {

			this.verb = decodeURIComponent(this.verb);
			this.prepareParty();

		} else {

			// Set theme name visually
			this.dom.name.innerHTML = this.mood;
			this.dom.name.setAttribute('href', `/#/choice/`);

			// Event Listeners : Stocker la référence a la fonction pour bien le remove
			this.submitFormFunction = this.submitForm.bind(this);

			this.dom.form.addEventListener('submit', this.submitFormFunction, false);

			// Show Form
			TweenMax.fromTo(this.dom.content, 0.4, {
				y: 30,
				opacity: 0
			}, {
				y: 0,
				opacity: 1,
				delay: 0.2
			});

			this.dom.content.classList.add('is-active');

		}

		// 3D
		scene3d.changeScene(this.mood);

	}

	close(_cb) {
		super.close();

		party.remove();

		this.dom.share.classList.remove('is-active');

		TweenMax.to(this.dom.end.all, 0.2, {
			opacity: 0,
			y: 20
		});

		// Evts
		this.dom.form.removeEventListener('submit', this.submitFormFunction);

		_cb();

	}

	/***** */
	/** CLASS METHODS **/

	submitForm(e) {
		console.log('submit');

		if (e)
			e.preventDefault();

		this.verb = this.dom.input.value;

		window.location = `/#/choice/${this.mood}/${encodeURIComponent(this.verb)}`;

	}

	prepareParty() {

		this.dom.loaderMood.innerHTML = this.mood;
		this.dom.loaderTheme.innerHTML = this.verb;

		this.dom.endMood.innerHTML = this.mood;
		this.dom.endTheme.innerHTML = this.verb;

		document.querySelector('.js-mood-name-link').setAttribute('href', `/#/choice/`);
		document.querySelector('.js-mood-theme-link').setAttribute('href', `/#/choice/${this.mood}`);

		TweenMax.to(this.dom.content, 0.2, {
			opacity: 0,
			y: -30
		});

		// On reset la barre
		this.dom.loader.style.display = 'block';

		TweenMax.fromTo(this.dom.loader, 0.2, {
			opacity: 0,
			y: 40,
			x: '-50%'
		}, {
			opacity: 1,
			y: 0,
			x: '-50%',
			delay: 0.12,
			onComplete: () => {
				party.loadData({
						'mood': this.mood,
						'user_query': this.verb
					},
					() => {
						this.onPartyLoaded();
					});
			}
		});

		// Change sound randomly picked in array
		if (MUSICPLAYER.isAlreadyPlaying === false) {
			MusicPlayer.changeVideo(VIDEOS[this.mood][Math.floor(Math.random() * VIDEOS[this.mood].length)]);
		}

	}

	createTimeline() {
		this.tl.fromTo(this.dom.end.triangle, 0.3, {
			opacity: 0,
			y: 30
		}, {
			opacity: 1,
			y: 0
		}, "-=0");
		this.tl.fromTo(this.dom.end.title, 0.3, {
			opacity: 0,
			y: 30
		}, {
			opacity: 1,
			y: 0
		}, "-=0.04");
		this.tl.fromTo(this.dom.end.subtitle, 0.3, {
			opacity: 0,
			y: 30
		}, {
			opacity: 1,
			y: 0
		}, "-=0.08");
		this.tl.fromTo(this.dom.end.year, 0.3, {
			opacity: 0,
			y: 30
		}, {
			opacity: 1,
			y: 0
		}, "-=0.12");
		this.tl.fromTo(this.dom.end.terms, 0.3, {
			opacity: 0,
			y: 30,
			x: '-50%'
		}, {
			opacity: 1,
			y: 0,
			x: '-50%'
		}, "-=0.12");
	}

	onPartyLoaded() {

		// On remet les styles du panneau de fin
		TweenMax.set(this.dom.end.all, {
			opacity: 1,
			y: 0
		});
		this.tl.restart();

		this.dom.share.classList.add('is-active');

		const link = encodeURIComponent(window.location.href);
		const text = encodeURIComponent("Viens faire la teuf avec moi ! #generateuf le générateur de teuf 🎉");

		var fbshare = document.querySelector('.share__fb');
		var twshare = document.querySelector('.share__tuite');
		// var linkshare = document.querySelector('.share__aurelien');

		// Insérer les liens de partage
		fbshare.setAttribute('href', `https://www.facebook.com/sharer/sharer.php?u=${link}`);
		twshare.setAttribute('href', `https://twitter.com/share?url=${link}&text=${text}`);
		// linkshare.setAttribute('href', `${link}`);

	}

}
