/**
 * Party est une classe statique et pas une State : on appelle directement ses méthodes
 * On en a besoin dans la state Mood car les Gifs sont positionnés sous le fond
 */

class Party {

	constructor() {

		this.dom = {
			container: $('.party__container'),
			cover: $('.party__container__cover')
		};

		this.name = 'dance disco bbq';
		this.mood = 'disco';

		this.sizes = [36, 16, 9, 4, 1];
		this.limit = 8;

		this.apikey = 'UjyJHobDxKY78gxLgJYJPMlUWK9Tmcqu';

		// Modernizr.on('webpanimation', function (result) {
		// 	if (result) {
		this.format_img = 'preview_webp';
		// 	} else {
		// 		this.format_img = 'preview_gif';
		// 	}
		// });

		this.gestionLoadingGif = [];

		this.timer = null;

		this.timeout = 1000;

		this.data = null;

		this.theLoader = document.querySelector(".loading-bar__avancement");
	}

	loadData(_data_party, _callback) {

		// this.name = _data_party.mood + ' ' + _data_party.verb;
		this.mood = _data_party.mood;


		// console.log('Party:: -> ', this.name);

		if (this.mood == 'disco') {
			this.name = 'soul train'
		}

		if (this.mood == 'techno') {
			this.name = 'rave'
		}

		if (this.mood == 'vintage') {
			this.name = 'jazz vintage dance'
		}

		if (this.mood == 'slow') {
			this.name = 'romantic dance'
		}


		let xhr = new XMLHttpRequest();
		let xhr2 = new XMLHttpRequest();
		xhr.open('GET', "http://api.giphy.com/v1/gifs/search?q=" + this.name + "&api_key=" + this.apikey + "&limit=" + this.limit);
		xhr2.open('GET', "http://api.giphy.com/v1/gifs/search?q=" + _data_party.user_query + "&api_key=" + this.apikey + "&limit=" + this.limit);
		// xhr2.open('GET', "http://api.giphy.com/v1/gifs/search?q=" + _data_party.user_query + ' dance' + "&api_key=" + this.apikey + "&limit=" + this.limit);

		xhr.send();
		// xhr2.send();
		xhr.onload = () => {
			if (xhr.status === 200) {

				// if (this.data === null) {
				// 	this.data = JSON.parse(xhr.responseText);
				// 	console.log('a')
				// } else {
				// 	var newdata = JSON.parse(xhr.responseText);
				// 	this.data.data = this.data.data.concat(newdata.data)
				// 	console.log('b')



				let data = JSON.parse(xhr.responseText);
				xhr2.send();

				xhr2.onload = () => {


					var newdata = JSON.parse(xhr2.responseText);

					data.data = data.data.concat(newdata.data);

					console.log(data.data)


					// let data = this.data;

					/***************************************************/
					/** Préload des gifs dans des images **/

					for (let i = 0; i < data.data.length; i++) {
						this.gestionLoadingGif[i] = new Array();
					}

					// Util pour checker WebP dans Utils
					const isWebP = check_webp_feature();

					for (let i = 0; i < data.data.length; i++) {
						var img = new Image();

						if (data.data[i].images[this.format_img] && isWebP) {
							img.src = data.data[i].images[this.format_img].url;
						} else {
							img.src = data.data[i].images['preview_gif'].url;
						}
						this.gestionLoadingGif[i]['url'] = img.src;

						img.onload = () => {
							this.gestionLoadingGif[i]['load'] = 1;

							var percent = (this.gestionLoadingGif.length / (data.data.length - 1)) * 100;
							var tmp = percent + '%';
							this.theLoader.style.width = tmp;

							var cmp = 0;
							for (let i = 0; i < this.gestionLoadingGif.length; i++) {
								if (this.gestionLoadingGif[i]['load'] == 1) {
									cmp++;
								}
							}

							if (cmp == data.data.length) {

								window.setTimeout(() => {

									document.querySelector(".loading-bar").style.display = 'none';

								}, 400);

								this.prepareBuild();
								this.buildMosaic(data);

								// To the Mood
								_callback();

								// var mosaic = document.querySelector(".party__container");
								// TweenMax.fromTo(mosaic,3,{filter:'hue-rotate(0deg)'},{filter:'hue-rotate(360deg)',repeat:-1,yoyo:true});
							}
						}
					}

				}
			}
		}




	}

	prepareBuild() {
		this.dom.cover.classList.add('is-active');
		this.dom.container.classList.add('show');
	}

	buildMosaic(data) {

		let size = this.sizes[Math.floor(Math.random() * this.sizes.length)];

		let length = this.gestionLoadingGif.length;

		this.dom.container.innerHTML = '';

		this.dom.container.className = this.dom.container.className.replace(new RegExp('\\b' + 'party__container-' + '(.*)?\\b', 'g'), '');


		for (let i = 0; i < size; i++) {

			let div = document.createElement('div');
			let rand = Math.floor(Math.random() * ((length - 1) - 0) + 0);
			div.style.backgroundImage = 'url(' + this.gestionLoadingGif[rand]['url'] + ')';

			/** AJOUT VARIATION DE COULEUR **/
			var tmp = '';



			/** AJOUT VARIATION SATURATION **/
			// var randomDeg = Math.random() * (300 - 0) + 0;
			// var tmp = 'saturate(' + Math.floor(randomDeg) + '%)';
			// div.style.filter = tmp;

			this.dom.container.appendChild(div);

			this.dom.container.classList.add('party__container-' + Math.sqrt(size));

		}
		if (this.mood == 'techno') {
			var randomDeg = Math.random() * 360;
			var randomSaturate = Math.random() * 2 + 1;
			tmp = 'hue-rotate(' + Math.floor(randomDeg) + 'deg) saturate(' + randomSaturate + ')';
			this.timeout = 500;
		}
		if (this.mood == 'disco') {
			var randomSaturate = Math.random() * 1.5;
			tmp = 'saturate(' + randomSaturate + ')';
			this.timeout = 1000;
		}
		if (this.mood == 'slow') {
			//rose
			tmp = 'saturate(2) hue-rotate(330deg)';
			this.timeout = 1600;
		}
		if (this.mood == 'vintage') {
			//rose
			tmp = 'saturate(.75) sepia(.25)';
			this.timeout = 800;
		}

		this.dom.container.style.filter = tmp;

		// this.timer = window.setTimeout(() => {
		// 	this.buildMosaic(data);
		// }, randomFromInterval(1000, 2000));
		this.timer = window.setTimeout(() => {
			this.buildMosaic(data);
		}, this.timeout);

	}

	// Clear tout
	remove() {

		// On reset la barre
		this.theLoader.style.width = '0%';

		this.dom.cover.classList.remove('is-active');
		this.dom.container.classList.remove('show');
		this.dom.container.innerHTML = '';
		window.clearTimeout(this.timer);
	}

}
