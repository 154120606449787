function getScript(source, callback) {
	var script = document.createElement('script');
	var prior = document.getElementsByTagName('script')[0];
	script.async = 1;

	script.onload = script.onreadystatechange = function (_, isAbort) {
		if (isAbort || !script.readyState || /loaded|complete/.test(script.readyState)) {
			script.onload = script.onreadystatechange = null;
			script = undefined;

			if (!isAbort) {
				if (callback) callback();
			}
		}
	};

	script.src = source;
	prior.parentNode.insertBefore(script, prior);
}

//https://davidwalsh.name/javascript-debounce-function
function debounce(func, wait, immediate) {
	var timeout;
	return function () {
		var context = this,
			args = arguments;
		var later = function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
}

function parseRequestURL() {

	let url = location.hash.slice(1).toLowerCase() || '/';
	let r = url.split("/");
	let request = {
		resource: null,
		id: null,
		verb: null
	};
	request.resource = r[1];
	request.id = r[2];
	request.verb = r[3];

	return request;
}

// IE11
// forEach polyfill
(function () {
	if (typeof NodeList.prototype.forEach === "function") return false;
	NodeList.prototype.forEach = Array.prototype.forEach;
})();

function check_webp_feature() {
	var testImages =  "UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA";
	var img = new Image();
	img.onload = function () {
		var result = (img.width > 0) && (img.height > 0);
		return result;
	};
	img.onerror = function () {
		return false;
	};
	img.src = "data:image/webp;base64," + testImages;
}


function randomFromInterval(min,max) // min and max included
{
    return Math.random() * (max - min) + min;
}
