// https://blog.prototypr.io/stunning-hover-effects-with-css-variables-f855e7b95330
class Button{

    constructor( _el ){

		this.button = _el;

    	this.init();

    }

    init(){

		this.button.addEventListener('mousemove', (e) => {

			const target = e.currentTarget;

			const x = e.pageX - target.getBoundingClientRect().left;
			const y = e.pageY - target.getBoundingClientRect().top;

			target.style.setProperty('--x', `${ x }px`);
			target.style.setProperty('--y', `${ y }px`);

		}, false);

	}

}
