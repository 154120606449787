if ( WEBGL.isWebGLAvailable() === false ) {

	console.log( WEBGL.getWebGLErrorMessage() );

}

class Scene3d {

	constructor() {

		this.controls = null;

		this.camera = null;
		this.scene = null;
		this.renderer = null;
		this.light = null;

		this.background = null;

		this.clock = new THREE.Clock();

		this.objectGroup = [];

		this.dom = {
			container: $( '.canvas' )
		};

		this.theme = '';

		this.init();

	}

	init() {

		this.camera = new THREE.PerspectiveCamera( 45, window.innerWidth / window.innerHeight, 0.25, 20 );

		// this.controls = new THREE.OrbitControls( this.camera );
		// this.controls.target.set( 0, 0, 0 );
		// this.controls.update();

		// Env Map

		//> Sky
		// var urls = [ 'dark-s_px.jpg', 'dark-s_nx.jpg', 'dark-s_py.jpg', 'dark-s_ny.jpg', 'dark-s_pz.jpg', 'dark-s_nz.jpg' ];
		// var loader = new THREE.CubeTextureLoader().setPath( 'assets/textures/cube/MilkyWay/' );

		//> Bridge
		var urls = [ 'right.jpeg', 'left.jpeg', 'top.jpeg', 'bottom.jpeg', 'front.jpeg', 'back.jpeg' ];
		var loader = new THREE.CubeTextureLoader().setPath( 'assets/textures/cube/car/' );

		this.background = loader.load( urls );

		this.scene = new THREE.Scene();

		// this.scene.background = this.background;

		// Grid de debug
		// var size = 20;
		// var divisions = 10;
		// var gridHelper = new THREE.GridHelper( size, divisions );
		// this.scene.add( gridHelper );

		// var light2 = new THREE.PointLight( 0x00ffcc, 20, 200 );
		// light2.position.set( 4, 30, -160 );
		// scene.add( light2 );

		// var light3 = new THREE.AmbientLight( 0x0000FF, 20, 100 );
		// light3.position.set( 30, -10, 30 );
		// scene.add( light3 );

		this.renderer = new THREE.WebGLRenderer( { antialias: true, alpha: true } );

		this.renderer.physicallyCorrectLights = true; // From https://gltf-viewer.donmccurdy.com/
		this.renderer.gammaOutput = true;
		this.renderer.gammaFactor = 2.2; // From https://gltf-viewer.donmccurdy.com/

		// renderer.setClearColor( 0xcccccc );

		this.renderer.setPixelRatio( window.devicePixelRatio );
		this.renderer.setSize( window.innerWidth, window.innerHeight );

		this.dom.container.appendChild( this.renderer.domElement );

		window.addEventListener( 'resize', () => { this.onWindowResize(); } , false );

		this.clock.start();

	}

	changeScene( _theme ) {

		// Clear previous scene
		this.clearScene();

		this.theme = _theme;

		if( this.theme === 'disco' ) {

			this.loadDiscoScene( 'discoball-080119-02.glb' );

		}else if( this.theme === 'slow' ) {

			this.loadSlowScene( 'heart03.glb' );

		}else if( this.theme === 'techno' ) {

			this.loadTechnoScene( 'korg-080119.glb' );

		}else if( this.theme === 'vintage' ) {

			this.loadVintageScene( 'vinyl-080119-03.glb' );

		}


	}

	loadDiscoScene( _model ) {

		// Change params
		this.camera.position.set( 0, 0, 4 );

		// model
		var loader = new THREE.GLTFLoader().setPath( 'assets/models/' );
		loader.load( _model,  ( gltf ) => {

			gltf.scene.traverse( ( child ) => {

				if ( child.isMesh ) {

					child.material.envMap = this.background;

					let cloned1 = child.clone();
					let cloned2 = child.clone();

					// On repositionne les mesh
					child.position.set(1,1.2,0);
					child.scale.set(1.1,1.1,1.1);

					// Dupliqué
					cloned1.scale.set(1,1,1);
					cloned1.position.set(0.6,-1,0);
					gltf.scene.add(cloned1);

					// Dupliqué
					cloned2.scale.set(1,1,1);
					cloned2.position.set(-1,-0.8,0);
					gltf.scene.add(cloned2);

					this.objectGroup.push(child);
					this.objectGroup.push(cloned1);
					this.objectGroup.push(cloned2);

				}

			} );

			this.scene.add( gltf.scene );

			// Tourner la camera vers la scene
			this.camera.lookAt( gltf.scene.position );

			// console.log(this.scene);

		}, undefined, function ( e ) {

			console.error( e );

		} );
	}

	loadSlowScene( _model ) {

		// Change params
		this.camera.position.set( 0, 0, 5 );

		// model
		var loader = new THREE.GLTFLoader().setPath( 'assets/models/' );
		loader.load( _model,  ( gltf ) => {

			gltf.scene.traverse( ( child ) => {

				if ( child.isMesh ) {

					child.material.envMap = this.background;

					// Duplicate model
					for (let index = 0; index < 30; index++) {

						let clone = child.clone();

						clone.scale.set(0.1,0.1,0.1);
						clone.position.set(
							randomFromInterval(-2.5, 2.5),
							randomFromInterval(-2.5, 1), Math.random()
						);

						clone.userData.period = randomFromInterval(3500, 4000);
						clone.userData.amplitude = randomFromInterval(0.8, 1.2);

						clone.userData.Yspeed = randomFromInterval(0.009, 0.011);

						clone.userData.rotZ = clone.rotation.z;

						gltf.scene.add(clone);
						this.objectGroup.push(clone);

					}

					// Faire la meme avec le child
					child.scale.set(0.1,0.1,0.1);
					child.position.set(
						randomFromInterval(-2, 2),
						randomFromInterval(-2, 2), Math.random()
					);

					this.objectGroup.push(child);

				}

			} );

			this.scene.add( gltf.scene );

			// Tourner la camera vers la scene
			this.camera.lookAt( gltf.scene.position );


		}, undefined, function ( e ) {

			console.error( e );

		} );
	}

	loadTechnoScene( _model ) {

		// Change params
		this.camera.position.set( 0, 4, 10 );

		// model
		var loader = new THREE.GLTFLoader().setPath( 'assets/models/' );
		loader.load( _model,  ( gltf ) => {

			// console.log(gltf.scene);

			gltf.scene.traverse( ( child ) => {


				if ( child.isMesh ) {

					child.material.envMap = this.background;

				}

			} );

			// Tourner la scene // les porg
			let clone1 = gltf.scene.clone();
			let clone2 = gltf.scene.clone();

			// Tourner la camera vers la scene
			this.camera.lookAt( gltf.scene.position );

			gltf.scene.rotation.set(0, -1.4, 0);
			gltf.scene.position.set(3.5, 2.1, 0);

			clone1.rotation.set(0, 2.2, 0);
			clone1.position.set(5,-3,0);

			clone2.rotation.set(0, 0, 0);
			clone2.position.set(-4.3, -2,0);

			this.scene.add( gltf.scene );
			this.scene.add( clone1 );
			this.scene.add( clone2 );

			gltf.scene.userData.rotateSpeed = 0.01;
			clone1.userData.rotateSpeed = 0.005;
			clone2.userData.rotateSpeed = 0.008;

			this.pivot = new THREE.Group();
			this.scene.add( this.pivot );
			this.pivot.add( gltf.scene );

			this.objectGroup.push(gltf.scene);
			this.objectGroup.push(clone1);
			this.objectGroup.push(clone2);

		}, undefined, function ( e ) {

			console.error( e );

		} );
	}

	loadVintageScene( _model ) {

		// Change params
		this.camera.position.set( 0, 4, 10 );

		this.light = new THREE.HemisphereLight( 0xFF0000, 0xFF0000 );
		this.light.position.set( 0, 10, 0 );
		this.scene.add( this.light );

		// model
		var loader = new THREE.GLTFLoader().setPath( 'assets/models/' );
		loader.load( _model,  ( gltf ) => {

			gltf.scene.traverse( ( child ) => {

				if ( child.isMesh ) {

					child.material.envMap = this.background;

				}

			} );

			// Tourner la scene // les porg
			let clone1 = gltf.scene.clone();
			let clone2 = gltf.scene.clone();

			// Tourner la camera vers la scene
			this.camera.lookAt( gltf.scene.position );

			gltf.scene.scale.set(1.2, 1.2, 1.2);
			gltf.scene.position.set(3, 2.2, 1);
			gltf.scene.rotation.set(0.4, 0, 0.2);

			clone1.scale.set(1.2, 1.2, 1.2);
			clone1.position.set(4, -2, 0);
			clone1.rotation.set(0.4, 0, 0.2);

			clone2.scale.set(1.2, 1.2, 1.2);
			clone2.position.set(-4.3, -2, 0.2);
			clone2.rotation.set(0.4, 0, 0.2);

			this.scene.add( gltf.scene );
			this.scene.add( clone1 );
			this.scene.add( clone2 );

			gltf.scene.userData.y = 2.2;
			clone1.userData.y = -2;
			clone2.userData.y = -2;

			gltf.scene.userData.amplitude = 1;
			clone1.userData.amplitude = 0.8;
			clone2.userData.amplitude = 1.2;

			gltf.scene.userData.period = 4000;
			clone1.userData.period = 8000;
			clone2.userData.period = 5000;

			this.pivot = new THREE.Group();
			this.scene.add( this.pivot );
			this.pivot.add( gltf.scene );

			this.objectGroup.push(gltf.scene);
			this.objectGroup.push(clone1);
			this.objectGroup.push(clone2);


		}, undefined, function ( e ) {

			console.error( e );

		} );
	}

	clearScene() {
		try {
			while(this.scene.children.length > 0){
				this.scene.remove(this.scene.children[0]);
			}
		} catch (error) {

		}
	} 

	onWindowResize() {

		this.camera.aspect = window.innerWidth / window.innerHeight;
		this.camera.updateProjectionMatrix();

		this.renderer.setSize( window.innerWidth, window.innerHeight );

	}

	animate() {

		requestAnimationFrame( () => { this.animate(); } );

		if( this.objectGroup ) {

			let time = this.clock.oldTime - this.clock.getElapsedTime();

			if( this.theme === 'disco' ){

					this.objectGroup.forEach( _el => _el.rotation.y += 0.01 );
			}

			if( this.theme === 'slow' ) {
					this.objectGroup.forEach( _el => {

						let nextX = _el.userData.amplitude * Math.sin( time * 2 * Math.PI / _el.userData.period ); // fonction d'oscillation

						_el.position.y += _el.userData.Yspeed;
						_el.rotation.z = _el.userData.rotZ + nextX;
						_el.position.z += 0.01;

						// If out of screen then ...
						if( _el.position.y >= 2.5 ) {
							_el.position.y = -2.5;
							_el.position.z = 0;
						}

					}
				);
			}

			if( this.theme === 'techno' ){

					this.objectGroup.forEach( _el => _el.rotation.y += _el.userData.rotateSpeed );
			}

			if( this.theme === 'vintage' ){

				//https://www.html5canvastutorials.com/advanced/html5-canvas-oscillation-animation/
				this.objectGroup.forEach( _el => {
					let nextX = _el.userData.amplitude* Math.sin( time * 2 * Math.PI / _el.userData.period); // fonction d'oscillation
					_el.position.y = _el.userData.y + nextX;
					_el.rotation.x += 0.01;
				});
			}

		}


		this.renderer.render( this.scene, this.camera );

	}
}
